import React, {createElement, useContext} from "react";
import useDemoInfo from "../../utils/useDemoInfo";
import FormContext, { useForm } from "../../components/FormContext";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {ContactSection} from '../../styles/ContactStyles'

const translations = {
  en: {
    Submit: 'Submit',
  },
  fr: {
    Submit: 'Soumettre',
  },
};
function getTranslation(key, locale) {
  return translations[locale] && translations[locale][key] ? translations[locale][key] : translations['en'][key];
};

export default function ContactForm({ data, pageContext, grid }) {



  const { executeRecaptcha } = useGoogleReCaptcha();
  const regulationContent = data.regulations[pageContext][0]._rawChildren;

  const  useFormContext  = useContext(FormContext) || {}; // Obsługa przypadku, w którym FormContext nie jest zdefiniowany

  const { state, handleChange} = useForm();
  const {fullname, email, phone, msg, accept} = state;
  const { submitDemoRequest } = useDemoInfo({ values: useFormContext.state});


  const handleDemoRequest = async (event) => {

    event.preventDefault();
    const token = await executeRecaptcha('homepage');

    try {
      await submitDemoRequest(event,token)
    } catch (error) {
      console.error('Wystąpił błąd podczas wysyłania żądania:', error);
    }
  };
  const onSubmit = async (event) => {
    await handleDemoRequest(event);
  }


  const handleSubmitForm = async (event) => {
    event.preventDefault();
    const token = await executeRecaptcha('homepage');
    const body = {
      fullname: useFormContext.state.fullname,
      email: useFormContext.state.email,
      phone: useFormContext.state.phone,
      msg: useFormContext.state.msg,
      accept: useFormContext.state.accept,
      token: token,
    };
    fetch('/api/contact',{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    }).then(res => {
      console.log(res)
    })
  }
  return (
    <ContactSection id={`grid-${grid}`}>
      <div>
        <h2>{data.title[pageContext]}</h2>
        <p>{data.description[pageContext]}</p>
      </div>
      <div>
        <form method="POST" action="/api/contact" onSubmit={handleSubmitForm}>
          <fieldset>
            <div className="field">
              <label htmlFor="input_fullname">Full name</label>
              <input id="input_fullname"
                     type="text"
                     value={fullname}
                     onChange={(e) => handleChange("fullname", e.currentTarget.value)}
              />
            </div>
            <div className="field_2cols">
              <div className="field">
                <label htmlFor="input_email">Email</label>
                <input id="input_email"
                       type="email"
                       value={email}
                       onChange={(e) => handleChange("email", e.currentTarget.value)}
                />
              </div>
              <div className="field">
                <label htmlFor="input_phone">Phone</label>
                <input id="input_phone"
                       type="tel"
                       value={phone}
                       onChange={(e) => handleChange("phone", e.currentTarget.value)}
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="input_msg">Message</label>
              <textarea id="input_msg"
                        value={msg}
                        onChange={(e) => handleChange("msg", e.currentTarget.value)}
              />
            </div>
            <div className="field regulation">
              <label htmlFor="input_accept">
                {
                  regulationContent &&
                  regulationContent.map((item, index) => (
                    createElement(item._type, {key: 'index'}, item.text)
                  ))
                }
              </label>
              <input type="checkbox"
                     id="input_accept"
                     value={accept}
                     onChange={(e) => handleChange("accept", e.currentTarget.value)}
              />
            </div>
            <button type="submit" className="button primary">
              {getTranslation('Submit', pageContext)}
            </button>
          </fieldset>
        </form>
      </div>
    </ContactSection>
  )
}